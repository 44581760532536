<template>
  <FormulateForm
    ref="fiscalPeriodsForm"
    name="fiscalPeriodsForm"
    v-model="formValues"
    @submit="submit"
    #default="{ isLoading, hasErrors }"
  >
    <!-- Inicio de año fiscal -->
    <FormulateInput
      name="nombre"
      label="Inicio de año fiscal"
      required
      validation="bail|required"
      validation-name="El Inicio de año fiscal"
      error-behavior="live"
    >
      <template #element>
        <date-picker
          name="nombre"
          :disabled="isLoading"
          class="w-100"
          v-model="selectedYear"
          type="year"
        />
      </template>
    </FormulateInput>

    <!-- Descripción del periodo fiscal -->
    <FormulateInput
      v-model="formValues.descripcion"
      label="Descripción"
      type="textarea"
      placeholder="Ingresa la descripción del periodo fiscal"
      :disabled="isLoading"
    />

    <!-- Activo o no -->
    <div class="form-group">
      <b-form-checkbox
        v-model="formValues.estatus"
        @change="getTextActivo"
        switch
        inline
        class="my-2 user-select-none"
      >
        {{ text }}
      </b-form-checkbox>
    </div>

    <div class="d-flex flex-row justify-content-between">
      <slot name="btn-cancel-place"></slot>
      <b-button
        type="submit"
        size="sm"
        :disabled="isLoading || hasErrors"
        class="btn ml-auto"
        :variant="getBtnProperties.variant"
      >
        {{ isLoading ? 'Procesando...' : getBtnProperties.text }}
      </b-button>
    </div>
  </FormulateForm>
</template>

<script>
import moment from 'moment'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

const formValues = () => ({
  nombre: '',
  estatus: false,
  descripcion: ''
})

export default {
  name: 'PeriodosFiscales',

  components: {
    DatePicker
  },

  props: {
    target: {
      type: String,
      required: false,
      default: 'create',
      validator: function (value) {
        return ['create', 'update'].indexOf(value) !== -1
      }
    },

    updateData: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },

  created () {
    if (this.target === 'update') {
      if (this.updateData === null || this.updateData === undefined) return

      this.formValues = this.updateData

      const fecha = new Date(this.updateData.nombre, 1, 1)
      this.selectedYear = fecha
      this.getTextActivo(this.updateData.estatus)
    }
  },

  data () {
    return {
      formValues: formValues(),

      text: null,

      selectedYear: null
    }
  },

  computed: {
    getBtnProperties () {
      return {
        text: this.target === 'create' ? 'Crear periodo fiscal' : 'Actualizar periodo fiscal',
        variant: this.target === 'create' ? 'success' : 'warning'
      }
    }
  },

  watch: {
    selectedYear: {
      handler (year) {
        if (!year) {
          this.formValues.nombre = null
          return
        }

        this.formValues.nombre = moment(year).format('YYYY')
      }
    }
  },

  methods: {
    async submit () {
      const getval =
        this.target === 'create'
          ? await this.create(this.formValues)
          : await this.update(this.formValues)

      this.$notify(getval, 'Periodo fiscal')

      if (getval.error) return

      this.reset()
    },

    async create () {
      return await this.$store.dispatch('fiscalPeriodsModule/createFiscalPeriod', this.formValues)
    },

    async update () {
      return await this.$store.dispatch('fiscalPeriodsModule/updateFiscalPeriod', {
        id: this.formValues.id,
        payload: this.formValues
      })
    },

    getTextActivo (data) {
      this.text = data === true ? 'Periodo fiscal activo' : 'Periodo fiscal no activo'
    },

    reset () {
      this.formValues = formValues()
      this.emitUpdated(true)
    },

    emitUpdated (value) {
      this.$emit('on-updated', value)
    }
  }
}
</script>
