<template>
  <FormulateForm
    ref="fiscalPeriodsCopyForm"
    name="fiscalPeriodsCopyForm"
    v-model="formValues"
    @submit="submit"
    #default="{ isLoading, hasErrors }"
  >
    <!-- Período fiscal de orígen -->
    <FormulateInput
      v-model="formValues.periodo_anterior_id"
      name="periodo_anterior_id"
      label="Período Fiscal orígen"
      required
      validation="bail|required"
      validation-name="El período de donde se copiarán los catálogos"
      error-behavior="live"
      type="select"
      placeholder="Seleccione un período fiscal"
      :options="periodos"
    />

    <!-- Descripción del periodo fiscal -->
    <FormulateInput
      v-model="formValues.duplicar_fuentes"
      label="Copiar Fuentes de Financiamiento"
      type="checkbox"
      :disabled="isLoading"
      label-position="before"
    />

    <FormulateInput
      v-model="formValues.duplicar_proyectos"
      label="Copiar Proyectos"
      type="checkbox"
      :disabled="isLoading"
      label-position="before"
    />

    <FormulateInput
      v-model="formValues.duplicar_unidades"
      label="Copiar Unidades Administrativas"
      type="checkbox"
      :disabled="isLoading"
      label-position="before"
    />

    <FormulateInput
      v-model="formValues.duplicar_partidas"
      label="Copiar Partidas"
      type="checkbox"
      :disabled="isLoading"
      label-position="before"
    />

    <FormulateInput
      v-model="formValues.duplicar_prestaciones"
      label="Copiar Prestaciones"
      type="checkbox"
      :disabled="isLoading"
      label-position="before"
    />

    <div class="d-flex flex-row justify-content-between">
      <slot name="btn-cancel-place"></slot>
      <b-button
        type="submit"
        size="sm"
        :disabled="isLoading || hasErrors"
        class="btn ml-auto"
        :variant="getBtnProperties.variant"
      >
        {{ isLoading ? 'Procesando...' : getBtnProperties.text }}
      </b-button>
    </div>
  </FormulateForm>
</template>

<script>
import '../../../node_modules/@braid/vue-formulate/themes/snow/snow.scss'

const formValues = () => ({
  periodo_id: null,
  periodo_anterior_id: '',
  duplicar_fuentes: false,
  duplicar_proyectos: false,
  duplicar_unidades: false,
  duplicar_partidas: false,
  duplicar_prestaciones: false
})

export default {
  name: 'PeriodosFiscales',

  props: {
    target: {
      type: String,
      required: false,
      default: 'create',
      validator: function (value) {
        return ['create', 'update'].indexOf(value) !== -1
      }
    },

    updateData: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },

  created () {
    if (this.target === 'update') {
      if (this.updateData === null || this.updateData === undefined) return

      this.formValues.periodo_id = this.updateData.id
    }
    this.load()
  },

  data () {
    return {
      formValues: formValues(),

      periodos: null
    }
  },

  computed: {
    getBtnProperties () {
      return {
        text: this.target === 'create' ? 'Crear periodo fiscal' : 'Copiar catálogos',
        variant: this.target === 'create' ? 'success' : 'warning'
      }
    }
  },

  methods: {
    async load () {
      if (this.dispatchPath === null) return

      this.loading = true
      var getval = null
      console.log(this.target)
      if (this.target === 'update') {
        getval = await this.$store.dispatch('fiscalPeriodsModule/getPreviousFiscalPeriods',
          { id: this.formValues.periodo_id })
      } else {
        getval = await this.$store.dispatch('fiscalPeriodsModule/getAllFiscalPeriods')
      }
      const list = getval.data.data
      this.loading = false
      this.periodos = list.map(f => ({ value: f.id, label: f.nombre }))

      return getval.error && this.$notify(getval, 'XSelect', { closeOnTimeout: false })
    },

    async submit () {
      const getval =
        this.target === 'create'
          ? await this.create(this.formValues)
          : await this.update(this.formValues)

      this.$notify(getval, 'Periodo fiscal')

      if (getval.error) return

      this.reset()
    },

    async create () {
      return await this.$store.dispatch('fiscalPeriodsModule/createFiscalPeriod', this.formValues)
    },

    async update () {
      return await this.$store.dispatch('fiscalPeriodsModule/updateCopyFiscalPeriod', this.formValues)
    },

    getTextActivo (data) {
      this.text = data === true ? 'Periodo fiscal activo' : 'Periodo fiscal no activo'
    },

    reset () {
      this.formValues = formValues()
      this.emitUpdated(true)
    },

    emitUpdated (value) {
      this.$emit('on-updated', value)
    }
  }
}
</script>
