<template>
  <div>
    <b-table
      v-if="isAvailable"
      :fields="fields"
      :items="items"
      striped
      bordered
      responsive
      hover
      small
      stacked="md"
    >
      <!-- Techo financiero -->
      <template #cell(techo_financiero)="row">
        <b-badge v-text="$formatNumber(row.item.techo_financiero)"></b-badge>
      </template>

      <!-- Estatus -->
      <template #cell(estatus)="data">
        <b-badge :variant="data.item.estatus ? 'success' : 'danger'">
          {{ data.item.estatus ? 'Activo' : 'No activo' }}
        </b-badge>
      </template>

      <!-- Acciones -->
      <template #cell(acciones)="data">
        <u-d-copy-controls
          :id="data.item.id"
          copyPath="fiscalPeriodsModule/getFiscalPeriodForCopy"
          editPath="fiscalPeriodsModule/getFiscalPeriod"
          deletePath="fiscalPeriodsModule/deleteFiscalPeriod"
          :createPermissions="[permissions.create]"
          :editPermissions="[permissions.update]"
          :deletePermissions="[permissions.delete]"
          :hideDeleteAction="data.item.tiene_dependencias"
        />
      </template>
    </b-table>
    <loading v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FiscalPeriodsList',

  props: {
    permissions: {
      type: Object,
      required: true
    }
  },

  created () {
    this.submit()
  },

  data () {
    return {
      fields: [
        { key: 'nombre', label: 'Nombre' },
        { key: 'descripcion', label: 'Descripción' },
        { key: 'techo_financiero', label: 'Techo financiero', sortable: true },
        { key: 'estatus', label: 'Estatus' },
        { key: 'acciones', label: 'Acciones' }
      ]
    }
  },

  computed: {
    ...mapGetters('fiscalPeriodsModule', {
      items: 'getAllFiscalPeriods',
      isAvailable: 'getAvailableList'
    })
  },

  methods: {
    async submit () {
      const { error, message } = await this.$store.dispatch('fiscalPeriodsModule/getFiscalPeriods')

      if (error) this.$notify({ error, message }, 'Periodos fiscales')
    }
  }
}
</script>
