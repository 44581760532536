var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-view',{attrs:{"icon":"calendar","title":"Períodos fiscales","header-type":"compact"}},[_c('check-authorization',{attrs:{"requiresAuthorizations":[_vm.permissions.update],"hide":""}},[_c('b-modal',{attrs:{"id":"periodosFiscalesModal","centered":"","title":"Título","no-close-on-backdrop":"","hide-header-close":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v("Editar periodo fiscal")]},proxy:true},{key:"default",fn:function(ref){
var cancel = ref.cancel;
return [_c('div',{staticClass:"d-block"},[_c('fiscal-periods-form',{attrs:{"target":"update","updateData":_vm.getCurrentEditing},on:{"on-updated":function($event){return cancel()}},scopedSlots:_vm._u([{key:"btn-cancel-place",fn:function(){return [_c('b-button',{attrs:{"variant":"black","size":"sm"},on:{"click":function($event){cancel()
                  _vm.setCurrentFiscalPeriod(null)}}},[_vm._v(" Cancelar ")])]},proxy:true}],null,true)})],1)]}}])})],1),_c('check-authorization',{attrs:{"requiresAuthorizations":[_vm.permissions.create],"hide":""}},[_c('b-modal',{attrs:{"id":"periodosFiscalesCreateModal","centered":"","title":"Título","no-close-on-backdrop":"","hide-header-close":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v("Seleccionar catálogos a copiar")]},proxy:true},{key:"default",fn:function(ref){
                  var cancel = ref.cancel;
return [_c('div',{staticClass:"d-block"},[_c('fiscal-periods-copy-form',{attrs:{"target":"create"},on:{"on-updated":function($event){return cancel()}},scopedSlots:_vm._u([{key:"btn-cancel-place",fn:function(){return [_c('b-button',{attrs:{"variant":"black","size":"sm"},on:{"click":function($event){cancel()
                  _vm.setCurrentFiscalPeriodForCopy(null)}}},[_vm._v(" Cancelar ")])]},proxy:true}],null,true)})],1)]}}])})],1),_c('check-authorization',{attrs:{"requiresAuthorizations":[_vm.permissions.create],"hide":""}},[_c('b-modal',{attrs:{"id":"periodosFiscalesCopyModal","centered":"","title":"Título","no-close-on-backdrop":"","hide-header-close":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v("Seleccionar catálogos a copiar")]},proxy:true},{key:"default",fn:function(ref){
                  var cancel = ref.cancel;
return [_c('div',{staticClass:"d-block"},[_c('fiscal-periods-copy-form',{attrs:{"target":"update","updateData":_vm.getCurrentEditingForCopy},on:{"on-updated":function($event){return cancel()}},scopedSlots:_vm._u([{key:"btn-cancel-place",fn:function(){return [_c('b-button',{attrs:{"variant":"black","size":"sm"},on:{"click":function($event){cancel()
                  _vm.setCurrentFiscalPeriodForCopy(null)}}},[_vm._v(" Cancelar ")])]},proxy:true}],null,true)})],1)]}}])})],1),_c('div',{staticClass:"card card-header-actions"},[_c('div',{staticClass:"card-header"},[_vm._v(" Períodos fiscales "),_c('check-authorization',{attrs:{"requiresAuthorizations":[_vm.permissions.create],"show-alert":false,"no-slots":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var authorized = ref.authorized;
                  var message = ref.message;
return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top",modifiers:{"top":true}}],attrs:{"title":!authorized ? message : ''}},[_c('button',{staticClass:"btn btn-success btn-sm",attrs:{"disabled":!authorized || _vm.isCreatingResource},on:{"click":function($event){return _vm.$isAuthorized(authorized, _vm.createFiscalPeriod)}}},[_vm._v(" Crear ")])])]}}])})],1),_c('div',{staticClass:"card-body p-2"},[_c('check-authorization',{attrs:{"requiresAuthorizations":[_vm.permissions.list]}},[_c('fiscal-periods-list',{attrs:{"permissions":_vm.permissions}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }